// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyABK89ciLVYKG7-UzVZXjUyWSpTycyez84',
  authDomain: 'infield-engineering.firebaseapp.com',
  projectId: 'infield-engineering',
  storageBucket: 'infield-engineering.appspot.com',
  messagingSenderId: '946233965487',
  appId: '1:946233965487:web:8a2c44cd8469319e138d5a',
  measurementId: 'G-E67BKD1MFB',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
