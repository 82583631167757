import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { FirebaseError } from '@firebase/util';
import { app } from './Firebase';
import { createUser } from './User';
import type { IUser } from './User';

const firebaseAuth = getAuth(app);

export interface IAuth {
  signIn(username: string, password: string): Promise<IUser | null>;
}

class Auth implements IAuth {
  async signIn(username: string, password: string): Promise<IUser | null> {
    try {
      const cred = await signInWithEmailAndPassword(firebaseAuth, username, password);
      const user = createUser(cred.user);
      return user;
    } catch (error: unknown) {
      if (error instanceof FirebaseError) {
        console.log(`FirebaseError: ${error.message} ${error.code}`);
        console.error(error.code);
      }
      return null;
    }
  }
}

export const auth: IAuth = new Auth();
