import React from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack } from '@mui/material';

export interface ModalActivityProps {
  transparency?: number;
  show: boolean;
  text?: string | undefined;
  size?: number | 'small' | 'large' | undefined;
}

export const ModalActivity: React.FC<ModalActivityProps> = ({
  show,
  text,
  size = 40,
  transparency = 0.9,
}: ModalActivityProps) => {
  return (
    <div>
      <Modal open={show} aria-labelledby='modal-modal-title' sx={{ backgroundColor: 'transparent' }}>
        <Stack
          direction='column'
          sx={{
            // transparency,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 220,
            bgcolor: 'background.paper',
            // bgcolor: 'transparent',
            boxShadow: 24,
            borderRadius: '16pt',
            p: 4,
          }}
          justifyItems='center'
          alignItems='center'
          spacing={2}
        >
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {text}
          </Typography>
          <CircularProgress size={size} />
        </Stack>
      </Modal>
    </div>
  );
};
