import React, { useCallback, useState } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
// import { useTheme } from '@mui/system';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { auth } from '../services/Auth';
import { ModalActivity } from '../components/ModalActivity';
import { ReactComponent as LargeLogo } from './../assets/svgs/large-logo.svg';

interface LoginProps {}

export const Login: React.FC<LoginProps> = () => {
  const [showActivity, setShowActivity] = useState<boolean>(false);
  const [collapseIn, setCollapseIn] = useState<boolean>(false);
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const doLogin = useCallback(
    async (e: React.SyntheticEvent) => {
      setShowActivity(true);
      e.preventDefault();

      console.log(`doLogin: ${username} ${password}`);
      const user = await auth.signIn(username, password);
      setShowActivity(false);
      setCollapseIn(!user);
      console.log(`User: ${JSON.stringify(user, null, 2)}`);
    },
    [password, username],
  );

  // const theme = useTheme();
  //   const Logo = theme.amenity.logos.active.combo;

  return (
    <Stack
      direction='column'
      sx={{
        backgroundImage: 'url(../images/InField.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPositionX: 'left',
        backgroundPositionY: 'top',
        backgroundAttachment: 'fixed',
        backgroundClip: 'border-box',
        width: '100vw',
        height: '100vh',
        display: 'flex',
      }}
    >
      <Stack
        pt={6}
        pl={2}
        alignContent='flex-start'
        alignSelf='flex-start'
        direction='row'
        spacing={1}
        alignItems='center'
      >
        <LargeLogo />
        <Typography noWrap alignSelf='end' variant='h2' sx={{ color: 'black', fontWeight: 'bold' }}>
          InField Engineering
        </Typography>
      </Stack>
      <Stack direction='row' spacing={5} justifyContent='center' alignItems='center'>
        <form onSubmit={doLogin}>
          <Stack direction='column' spacing={2} pt={12} sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Collapse in={collapseIn}>
              <Alert severity='error'>
                <AlertTitle>Error</AlertTitle>
                Incorrect username/password used. <strong>Please try again</strong>
              </Alert>{' '}
            </Collapse>
            <TextField
              label='Email'
              variant='filled'
              sx={{ alignSelf: 'stretch', backgroundColor: 'white', borderRadius: '5px' }}
              autoComplete='Username'
              value={username}
              onChange={(event) => {
                setUserName(event.target.value);
              }}
            />
            <TextField
              label='Password'
              variant='filled'
              sx={{ alignSelf: 'stretch', backgroundColor: 'white', borderRadius: '5px' }}
              type='password'
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              autoComplete='current-password'
            />
            <Button type='submit' variant='contained'>
              Log In
            </Button>
            <a href='help/utah/site-form-manual.htm'>Help</a>
          </Stack>
        </form>
        <ModalActivity size={60} show={showActivity} text='Logging in...' />
      </Stack>
    </Stack>
  );
};
