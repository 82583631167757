import type { User as FBUser } from 'firebase/auth';

export interface IUser {
  getEmail(): string | null;

  getDisplayName(): string | null;

  getUid(): string;
}

export function createUser(user: FBUser): IUser {
  return new User(user);
}

class User implements IUser {
  private fbUser: FBUser;

  constructor(user: FBUser) {
    console.log('User');
    this.fbUser = user;
  }
  getEmail(): string | null {
    return this.fbUser.email;
  }

  getDisplayName(): string | null {
    return this.fbUser.displayName;
  }

  getUid(): string {
    return this.fbUser.uid;
  }
}
